import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentProcessing from '../components/ServiceDetails/ServiceDetailsContentProcessing'
//import RelatedServices from '../components/ServiceDetails/RelatedServices'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Data Processing" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details Processing" 
            />
            <ServiceDetailsContentProcessing />
            <Footer />
        </Layout>
    );
}

export default Details