import React from 'react'
import ServiceSidebarProcessing from './ServiceSidebarProcessing'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentProcessing = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Data Processing Services</span>
                            <h3>About</h3>
                            <p>Starting with Analysis, Mapping Specifications, ETL Development, Orchestration and Scheduling, the entire ETL Process is covered for effectiveness. We consider cost, performance and scalability.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Cover</h3>
                                        <ul>
                                            <li>Architecture</li>
                                            <li>ETL</li>
                                            <li>Orchestration</li>
                                            <li>Scheduling</li>
                                            <li>Data Warehousing</li>
                                            <li>Performance</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Collecting and Manipulating Data for creation of relevant information is a complex process. We have extensive experience in all aspects of it, and we cover the stages of a performant, scalable and cost reduced solution. We can deliver knowledge in the best tools used on the market in the process to gain time-to-market effectiveness.</p>
                            <p>Throughout our partnership with AbInitio we also offer their solutions for processing designed for speed. Facilitating executions in batch, streaming, in-memory or microservice architecture, or supporting checkpoint restarts with application monitoring and alerting, it is a powerful data processing platform.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarProcessing />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentProcessing